var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    { attrs: { title: "Rapportage Zaakbegeleiding" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "justify-space-between": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "info-icon", attrs: { shrink: "" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.openReadMoreDialog("mutatie")
                                  }
                                }
                              },
                              on
                            ),
                            [_vm._v("info_outline")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("klik voor meer info")])]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "tw-px-16", attrs: { wrap: "", "align-end": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "mb-2": "" } },
            [
              _c("PeriodFilter", {
                attrs: {
                  to: _vm.filters.to,
                  from: _vm.filters.from,
                  clearable: false
                },
                on: { change: _vm.applyDateFilters }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "text-xs-right", attrs: { sm12: "" } },
            [
              _c(
                "MiButton",
                {
                  attrs: { color: "primary", small: "true", outline: "true" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.applyFilters()
                    }
                  }
                },
                [_vm._v("Pas filters toe")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [_c("v-flex", { staticClass: "separator", attrs: { xs12: "" } })],
        1
      ),
      _vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c("StatisticsView", {
                  attrs: {
                    items: _vm.reportTypeResults,
                    columns: _vm.mediatorColumns
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "totals",
                        fn: function() {
                          return _vm._l(_vm.mediatorColumns, function(
                            column,
                            columnIndex
                          ) {
                            return _c(
                              "v-flex",
                              { key: columnIndex, class: column.class },
                              [
                                columnIndex === 0
                                  ? [_vm._v("Totaal")]
                                  : columnIndex === 1
                                  ? void 0
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getColumnTotal(columnIndex)
                                          ) +
                                          " "
                                      )
                                    ]
                              ],
                              2
                            )
                          })
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3574905836
                  )
                })
              ],
              1
            ),
            !_vm.reportTypeResults
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("span", [
                    _vm._v(
                      'Druk op de knop "Pas filters toe" om de resultaten te bekijken.'
                    )
                  ])
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }