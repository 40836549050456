import { Rpc } from '@/models/Rpc';
import { Component, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import StatisticsView from '@/components/statistics-view/StatisticsView.vue';
import { StatisticsColumn } from '@/components/statistics-view/StatisticsView';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import { userLevelLabels } from '@/models/User';
import { RapportageZaakbegeleiding } from '@/support/Info';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';

@Component<AppointmentCount>({
  components: {
    StatisticsView,
    PeriodFilter,
  },
})
export default class AppointmentCount extends AbstractMoreInfoDialog {
  public $pageTitle = 'Rapportage Zaakbegeleiding';

  protected isLoading = false;

  protected isLoadingFilters = true;

  protected reportTypeResults: AppointmentCountItem[] = [];

  protected activeMoreInfo: MoreInfo = { title: RapportageZaakbegeleiding[0].title, description: RapportageZaakbegeleiding[0].description };

  protected filters: AppointmentCountFilters = {
    from: '',
    to: '',
    level: null,
  };

  protected opnameVariantColumns: StatisticsColumn[] = [];

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    this.isLoadingFilters = true;
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.filters.from = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.filters.to = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;
    this.isLoadingFilters = false;
  }

  protected getColumnTotal(columnIndex : number) {
    let total = 0;

    this.reportTypeResults.forEach((item: AppointmentCountItem) => {
      this.mediatorColumns.forEach((column: StatisticsColumn, index: number) => {
        if ((columnIndex === index) && (column && column.transform) && item.data) {
          total += Number(column.transform(item));
        }
      });
    });

    return total;
  }

  protected get mediatorColumns(): StatisticsColumn[] {
    return [
      {
        key: 'name',
        name: 'Naam',
        class: 'xs2',
      },
      {
        key: 'level',
        name: 'Level',
        class: 'xs2 text-right',
        transform: (item: AppointmentCountItem) => userLevelLabels[item.level],
      },
      {
        key: 'data', // physical
        name: 'Fysieke Afspraken',
        class: 'xs2 text-right',
        transform: (item: AppointmentCountItem) => item.data.physical,
      },
      {
        key: 'data', // call
        name: 'Telefonische Afspraken',
        class: 'xs2 text-right',
        transform: (item: AppointmentCountItem) => item.data.call,
      },
      {
        key: 'data', // opname_op_afstand
        name: 'OOA',
        class: 'xs2 text-right',
        transform: (item: AppointmentCountItem) => item.data.opname_op_afstand,
      },
      {
        key: 'data', // total
        name: 'Totaal',
        class: 'xs2 text-right',
        transform: (item: AppointmentCountItem) => item.data.total,
      },
    ];
  }

  protected applyFilters() {
    this.fetchStatistics();
  }

  protected async fetchStatistics() {
    this.isLoading = true;

    const reportTypePayload = {
      signature: 'mediator:appointment-count',
      body: this.filters,
    };

    const reportTypeResponse = await new Rpc().rpcPost(reportTypePayload, false);
    this.reportTypeResults = reportTypeResponse.data as AppointmentCountItem[];
    this.isLoading = false;
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected applyDateFilters(from: string, to: string) {
    this.filters.from = from;
    this.filters.to = to;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [{ name: 'Rapportage Zaakbegeleiding' }],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
  }
}

interface AppointmentCountFilters {
  from: string;
  to: string;
  level: string | null;
}

interface AppointmentCountItem {
  data: Record<string, number>;
  level: string;
  name:string;
}
